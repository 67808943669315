/** ***********************************************************************************
 * Atoms / Typography / Paragraph
 *********************************************************************************** */

@use '~/src/styles/utilities.scss' as *;

p {
  font: var(--cn-global--Font-paragraph--md-medium);
  line-height: var(--cn-global--LineHeight-sm);
  margin-block-end: pxToRem(25px);
}
