/** ***********************************************************************************
 * Strong text styles
 *********************************************************************************** */

body {
  --cn-c-page__strong--FontWeight: var(--cn-global--FontWeight-semibold);
}



b,
strong {
  font-weight: var(--cn-c-page__strong--FontWeight);
}
