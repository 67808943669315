/** ***************************************************************************
 * Ecommerce / Organisms / Related Products
 *************************************************************************** */

@use 'utopia-core-scss/src/utopia' as utopia;
@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-related_products {
  > .container > h2 {
    grid-column: 1 / -1;
    margin-block-end: pxToRem(45px);
  }

  .subtitle {
    grid-column: 1 / -1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: pxToRem(10px);
    margin-block-end: pxToRem(51px);

    @include dispatchToContextBreakpoints($grid-breakpoints) {
      margin-block-end: pxToRem(84px);
    }
  }

  .product-list {
    grid-column: 1 / -1;
    overflow-x: auto;

    article {

      &:last-child {
        padding-inline-end: var(--cn-global--content-grid--offsetsize);
      }

      > div {
        min-width: pxToRem(263px);
      }

      @include dispatchToContextBreakpoints($grid-breakpoints) {
        grid-column: span 3;

        &:last-child {
          padding-inline-end: unset;
        }
      }
    }

    @media all and (pointer:coarse) {
      scrollbar-width: none;
    }
  }

  .images-slider {
    display: grid;
    grid-template-columns: repeat(var(--cn-global--content-grid--md--columncount), 1fr);
    grid-gap: 0 var(--cn-c-page--Grid-Gap);
    // display: flex;
    // gap: 15px;
    // overflow-x: auto;
    margin-inline-end: calc(-1 * var(--cn-global--content-grid--offsetsize));
    padding-block-end: pxToRem(10px);

    @media all and (min-width: 1200px) {
      margin-inline-end: 0;
    }
  }
}
