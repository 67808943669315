/** ***************************************************************************
 * Conchiglia SASS utilities
 *************************************************************************** */

 @use "sass:map";
@use "sass:math";

$base-size: 16px;

/* Function for converting a px based font-size to rem. */
@function pxToRem($size) {
  @return math.div($size, $base-size) * 1rem;
}

@mixin dispatchToContextBreakpoints($grid-breakpoints) {
  @media all and (min-width: map.get($grid-breakpoints, xl)) {
    .E_COMMERCE & {
      @content;
    }
  }

  @media all and (min-width: map.get($grid-breakpoints, xxl)) {
    @content;
  }
}
