/** ***************************************************************************
 * Ecommerce / Organisms / Address Form
 *************************************************************************** */

 @use "sass:map";
@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-address_form {
    grid-column: 1 / -1;

    .form-fields {
      row-gap: pxToRem(8px);
      column-gap: pxToRem(12px);

      > * {
        grid-column: 1 / -1;
      }

      @media screen and (min-width: map.get($grid-breakpoints, md)) {
        .first_name, .last_name {
          grid-column: span 6;
        }

        .line1, .line2, .line3, .country, .state, .phone_number, .notes {
          grid-column: span 12;
        }

        .country {
          z-index: 2;
        }

        .postcode {
          grid-column: span 4;
        }

        .line4 {
          grid-column: span 8;
        }
      }

    }

    .cn-c-button[type="submit"] {
      grid-column: 1 / -1;
      margin-block-start: pxToRem(20px);

      @media screen and (min-width: map.get($grid-breakpoints, md)) {
        margin-block-start: pxToRem(40px);
      }
    }

    .cn-c-custom_select .custom_select-inner {
      border: none;
      font: var(--cn-global--Font-label--sm-medium);

      label {
        font: var(--cn-global--Font-label--xs-regular);
        color: var(--cn-global--palette-black--70);
        letter-spacing: 0.2px;
      }

      .selected-option {
        padding: unset;
      }

      .dropdown {
        border: 1px solid #ccc;
        background-color: #fff;
        width: calc(100% - 2 * 1.25rem);
        top: pxToRem(40px);
      }

      .option-title {
        font-weight: var(--cn-global--FontWeight-medium);
      }

      .option:hover {
        background-color: #f1f1f1;
      }

      &::after {
        right: pxToRem(23px);
      }
    }

    @media screen and (min-width: map.get($grid-breakpoints, lg)) {
      grid-column: span 9;
    }
}
