/******************************************************************************
 * Ecommerce / Molecules / Digital CTA
 *****************************************************************************/

@use "~/src/styles/utilities.scss" as *;

.cn-c-digital_cta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: pxToRem(20px);

  > p {
    margin-block: pxToRem(16px);
  }

  > a {
    display: flex;
    align-items: center;
    column-gap: pxToRem(20px);
  }

  .cn-c-header_heading {
    display: flex;
    gap: 0.3125rem;
    padding-inline-start: 0;

    .context-label {
      font-weight: var(--cn-global--FontWeight-medium);
      color: var(--cn-c-page--AccentColor);
    }
  }

  .cn-c-button--icon-only {
    --cn-c-button--icon-only--Dimension: #{pxToRem(31px)};
    --cn-c-button--icon-only--Padding: #{pxToRem(5px)};

    svg {
      block-size: pxToRem(20px);
    }
  }
}
