/******************************************************************************
 * Ecommerce / Molecules / Cart Item
 *****************************************************************************/

 @use "sass:map";
@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-cart_item {
  padding-block: pxToRem(27px) pxToRem(9px);
  border-block-end: 1px solid var(--cn-global--palette-black--15);

  display: flex;
  flex-wrap: wrap;
  gap: pxToRem(24px);
  row-gap: 11px;

  img {
    height: pxToRem(147px);
    width: pxToRem(106px);
    object-fit: cover;
  }

  .info-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: pxToRem(14px);

    h1 {
      font: var(--cn-global--FontWeight-semibold) var(--cn-global--FontSize-title--md) var(--cn-global--FontFamily-default);
      margin-block-start: pxToRem(15px);
      margin-block-end: pxToRem(7px);
    }

    .cn-c-price .cn-c-label {
      font-size: pxToRem(16px);
      font-weight: var(--cn-global--FontWeight-regular);
    }

    .readonly-quantity {
      display: flex;
      align-items: baseline;
      gap: pxToRem(8px);
      margin-block-end: pxToRem(15px);
    }
  }

  .action-buttons {
    display: flex;
    gap: pxToRem(10px);
    justify-content: space-between;

    .cn-c-add_to_cart_cta {
      --cn-c-add_to_cart--BackgroundColor: var(--cn-c-page--BackgroundColor);
      --cn-c-add_to_cart--Color: var(--cn-c-page--Color);
      border: 1px solid var(--cn-c-add_to_cart--Color);
      height: pxToRem(40px);
      padding-block: pxToRem(12px);
      padding-inline: pxToRem(15px);
      width: fit-content;

      .item-amount,
      .cn-c-add_to_cart .label {
        font-size: pxToRem(14px);
      }

      .cn-c-add_to_cart {
        padding-inline-end: pxToRem(4px);
      }

      .vertical-separator {
        background-color: var(--cn-global--border--Color);
      }

      &:hover {
        --cn-c-add_to_cart--BackgroundColor: var(--cn-c-page--Color);
        --cn-c-add_to_cart--Color: var(--cn-c-page--BackgroundColor);

        .vertical-separator {
          background-color: var(--cn-global--border--Color--DarkTheme);
        }
      }
    }

  }

  .hidden-fields {
    display: none;
  }

  .cn-c-button--trash {
    --cn-c-button--hover--BackgroundColor: transparent;
    --cn-c-button--hover--Color: var(--cn-c-page--Color);
    --cn-c-button--PaddingInline: 0;
    align-self: flex-end;
    border: none;
    width: fit-content;
    flex-direction: row-reverse;
    gap: pxToRem(8px);

    > .label {
      text-transform: none;
      text-decoration: underline;
      letter-spacing: initial;
      font-size: pxToRem(12px);

      @media screen and (min-width: map.get($grid-breakpoints, sm)) {
        display: none;
      }
    }

    @media screen and (min-width: map.get($grid-breakpoints, sm)) {
      --cn-c-button--hover--BackgroundColor: var(--cn-c-page--Color);
      --cn-c-button--hover--Color: var(--cn-c-page--BackgroundColor);
      --cn-c-button--PaddingInline: #{pxToRem(5px)};
      gap: unset;
      border: 1px solid;
      height: pxToRem(40px);
      width: pxToRem(40px);
    }
  }

  @media screen and (min-width: map.get($grid-breakpoints, sm)) {
    padding-block: pxToRem(20px);
  }

  @media screen and (min-width: map.get($grid-breakpoints, xl)) {
    gap: pxToRem(32px);
  }
}
