/******************************************************************************
 * Ecommerce / Atoms / Button / Roadmap
 *****************************************************************************/

.cn-c-button.cn-m-roadmap {
  --cn-c-button--m-roadmap--Color: var(--cn-global--palette-white);
  --cn-c-button--m-roadmap--PaddingInline: 1rem;  /* 17px */


  /**
   * Active indicator
   */

  --cn-c-button--m-roadmap__bullet--BackgroundColor: var(--cn-c-page--AccentColor);
  --cn-c-button--m-roadmap__bullet--BlockSize: .75rem;  /* 12px */
  --cn-c-button--m-roadmap__bullet--Transform: scale(0);
  --cn-c-button--m-roadmap__bullet--TransitionDuration: var(--cn-global--Duration--xs);


  /**
   * Icon
   */

  --cn-c-button--m-roadmap__icon--BlockSize: .875rem;  /* 14px */
}



/**
 * Component
 * 1: pill-shaped
 */

.cn-c-button.cn-m-roadmap {
  --cn-c-button--Color: var(--cn-c-button--m-roadmap--Color);
  align-items: center;
  border-radius: 100vmax;  /* 1 */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 2.625rem;
  padding-inline: var(--cn-c-button--m-roadmap--PaddingInline);
  text-align: center;
  background-color: var(--cn-global--palette-sky-blue--18);
  min-width: 0;
}
.cn-c-button.cn-m-roadmap span {
  font-size: 30px;
  flex: 1;
  white-space: nowrap;
  padding-inline: 15px;
  /* width: 83%; */
  /* overflow: hidden; */
}
.cn-c-button.cn-m-roadmap:hover {
  background-color: var(--cn-global--palette-bright-light-blue--40);
}

@supports not (padding-inline: 0) {
  .cn-c-button.cn-m-roadmap {
    padding-left: var(--cn-c-button--m-roadmap--PaddingInline);
    padding-right: var(--cn-c-button--m-roadmap--PaddingInline);
  }
}



/**
 * Active indicator
 */

.cn-c-button.cn-m-roadmap::before {
  aspect-ratio: 1;
  background-color: var(--cn-c-button--m-roadmap__bullet--BackgroundColor);
  block-size: var(--cn-c-button--m-roadmap__bullet--BlockSize);
  border-radius: 50%;
  content: "";
  filter: blur(2px);
  transform: var(--cn-c-button--m-roadmap__bullet--Transform);
  transition: transform var(--cn-c-button--m-roadmap__bullet--TransitionDuration) ease;
}

@supports not (block-size: 0) {
  .cn-c-button.cn-m-roadmap::before {
    width: var(--cn-c-button--m-roadmap__bullet--BlockSize);
  }
}

@supports not (aspect-ratio: 1) {
  .cn-c-button.cn-m-roadmap::before {
    height: var(--cn-c-button--m-roadmap__bullet--BlockSize);
    width: var(--cn-c-button--m-roadmap__bullet--BlockSize);
  }
}



/**
 * Icon
 */

.cn-c-button.cn-m-roadmap::after {
  aspect-ratio: 1;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EBottoni/plus%3C/title%3E%3Cg id='Bottoni/plus' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Ccircle id='Oval' stroke='rgba(179, 238, 255, 1)' stroke-linecap='round' stroke-linejoin='round' cx='8' cy='8' r='7'%3E%3C/circle%3E%3Crect id='Rectangle' fill='rgba(179, 238, 255, 1)' x='4.5' y='7.5' width='7' height='1' rx='0.5'%3E%3C/rect%3E%3Crect id='Rectangle' fill='rgba(179, 238, 255, 1)' x='7.5' y='4.5' width='1' height='7' rx='0.5'%3E%3C/rect%3E%3C/g%3E%3C/svg%3E%0A");  background-size: cover;
  block-size: var(--cn-c-button--m-roadmap__icon--BlockSize);
  content: "";
  /* transition: all var(--cn-global--Duration--xs) ease; */
}

@supports not (block-size: 0) {
  .cn-c-button.cn-m-roadmap::before {
    width: var(--cn-c-button--m-roadmap__icon--BlockSize);
  }
}

@supports not (aspect-ratio: 1) {
  .cn-c-button.cn-m-roadmap::before {
    height: var(--cn-c-button--m-roadmap__icon--BlockSize);
    width: var(--cn-c-button--m-roadmap__icon--BlockSize);
  }
}
