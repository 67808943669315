/******************************************************************************
 * Ecommerce / Molecules / Variant
 *****************************************************************************/

@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-variant {
  > p {
    margin-block: 0;
  }

  .options {
    display: flex;
    flex-wrap: wrap;
    gap: pxToRem(22px);
    margin-block-start: pxToRem(5px);
    padding-block: pxToRem(12px);
    border-block: 1px solid var(--cn-global--palette-black--30);

    .cn-c-button {
      font: var(--cn-global--Font-label--xs-book);
    }
  }
}
