/** ***************************************************************************
 * Ecommerce / Organisms / Billing Address Section
 *************************************************************************** */

@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-billing_address_section {
  h3 {
    margin-block-end: pxToRem(29px);
  }

  h4 {
    margin-block-start: pxToRem(86px);
  }

  .same-delivery-button {
    grid-column: 1 / -1;
    margin-block-end: pxToRem(40px);
  }

  .current-addresses {
    grid-gap: var(--cn-c-page--Grid-Gap);
  }
}
