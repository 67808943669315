/** ***************************************************************************
 * Ecommerce / Atoms / Button / Thankful
 *************************************************************************** */

.cn-c-button-thankful {
  --cn-c-button-thankful--Color: var(--cn-global--palette-dark-blue);
  --cn-c-button-thankful--Font: var(--cn-global--Font-label-md-regular);
  --cn-c-button-thankful--RowGap: .5em;  /* 8px */


  /**
   * Icon
   */

  --cn-c-button-thankful__icon--BlockSize: 2.25em;  /* 36px */
}



/**
 * Component
 * 1: reset
 */

.cn-c-button-thankful {
  appearance: none;  /* 1 */
  background-color: transparent;  /* 1 */
  border: 0;  /* 1 */
  color: var(--cn-c-button-thankful--Color);
  display: inline-grid;
  font: var(--cn-c-button-thankful--Font);
  grid-row-gap: var(--cn-c-button-thankful--RowGap);
  grid-template-row: repeat(2, auto);
  justify-items: center;
  margin: 0;  /* 1 */
  padding: 0;  /* 1 */
}



/**
 * Pill
 */

.cn-c-button-thankful .cn-c-button {
  grid-column: 1;
  grid-row: 1;
  visibility: visible;
}

.cn-c-button-thankful:disabled .cn-c-button {
  visibility: hidden;
}




/**
 * Icon
 */

.cn-c-button-thankful > svg {
  block-size: var(--cn-c-button-thankful__icon--BlockSize);
  grid-column: 1;
  grid-row: 1;
  visibility: hidden;
  background-image: linear-gradient(270deg, rgba(0, 139, 194, 1) 0%, rgba(0, 83, 143, 1) 100%);
  border-radius: 23px;
}

.cn-c-button-thankful:disabled > svg {
  visibility: visible;
}

@supports not (block-size: 0) {
  .cn-c-button-thankful > svg {
    height: var(--cn-c-button-thankful__icon--BlockSize);
  }
}


/** Circle */
.cn-c-button-thankful > svg > circle {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  transition:
    stroke-dashoffset
    var(--cn-global--Duration--sm)
    linear;
}

.cn-c-button-thankful:disabled > svg > circle {
  /* stroke-dashoffset: 0; */
}

/** Check mark */
.cn-c-button-thankful > svg > path {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  color: white;
  transition:
    stroke-dashoffset
    var(--cn-global--Duration--sm)
    linear
    var(--cn-global--Duration--sm);
}

.cn-c-button-thankful:disabled > svg > path {
  stroke-dashoffset: 0;
}



/**
 * Thanks
 */

.cn-c-button-thankful > span:last-child {
  opacity: 0;
  transition: opacity var(--cn-global--Duration--sm) linear;
  visibility: hidden;
}

.cn-c-button-thankful:disabled > span:last-child {
  opacity: 1;
  visibility: visible;
}


.cn-c-button-thankful > .disabled-label {
  font: var(--cn-global--Font-label--sm-medium);
}
