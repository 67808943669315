/** ***************************************************************************
 * Ecommerce / Organisms / CMS plugins / Member slider
 *************************************************************************** */

 @use "sass:map";
 @use 'utopia-core-scss/src/utopia' as utopia;
 @use "~/src/styles/utilities.scss" as *;
 @use "~/src/styles/shared/variables.module.scss" as *;
 @import '@splidejs/splide/dist/css/splide.min.css';

.cn-c-members {
  grid-column: 1 / -1;
  align-items: center;
  position: relative;

  h2 {
    grid-column: span 3;

    @media screen and (min-width: map.get($grid-breakpoints, md)) {
      margin-block-start: pxToRem(165px);
    }
  }

  .cn-c-member_slider {
    grid-column: span 9;

    // > div {
    //   max-width: pxToRem(337px);
    //   display: flex;
    //   flex-direction: column;
    //   gap: pxToRem(22px);
    // }

  }
}

.cn-c-member_slider > .splide {
  @include utopia.generateClamp((
    "minWidth": 375,
    "maxWidth": 600,
    "minSize": 21,
    "maxSize": 30,
    "prefix": "fluid-",
  ));
  --splide-items-gap: #{var(--fluid-21-30)};
  position: initial;
  margin-right: calc(-1 * var(--cn-global--content-grid--offsetsize)) !important;

  .splide__track {
    overflow-y: visible;
    overflow-x: visible;
    margin-block-end: unset;
    clip-path: inset(-100vw -100vw -100vw 0);
  }

  .splide__list {
    align-items: flex-start;
  }

  .splide__slide {
    background-color: unset;
    border-radius: 4px;
    padding-block: unset;
    padding-inline: unset;

    &:last-child {
      padding-inline-end: var(--cn-global--content-grid--offsetsize);
    }

    .main-content {
      width: unset;
      display: flex;
      flex-direction: column;
      gap: pxToRem(22px);

      .cn-c-button {
        margin-block-start: unset;
      }
    }

    .cn-c-image_wrapper {
      position: initial;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      width: pxToRem(295px);
      height: pxToRem(390px);

      .mask-on-image {
        display: none;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 24px;
        height: 100%;
      }

      @media screen and (min-width: map.get($grid-breakpoints, md)) {
        width: pxToRem(337px);
        height: pxToRem(449px);
      }
    }

    .infos {
      display: flex;
      flex-direction: column;
      gap: pxToRem(10px);
    }

    .member-name {
      font: var(--cn-global--FontWeight-semibold) pxToRem(22px)/#{1.136} var(--cn-global--FontFamily-default);
    }

    .member-email {
      --cn-c-button--PaddingInline: unset;
      --cn-c-button--outline--Color: var(--cn-c-page--BackgroundColor);
      --cn-c-button--outline--BackgroundColor: var(--cn-c-page--Color);
      --cn-c-button--BorderColor: var(--cn-c-page--Color);
      --cn-c-button--hover--Color: var(--cn-c-page--BackgroundColor);
      --cn-c-button--hover--BackgroundColor: var(--cn-c-page--AccentColor);
      width: pxToRem(31px);
      height: pxToRem(31px);

      > svg {
        block-size: pxToRem(14px);
      }
    }

    @keyframes fadeIn {
      0% { opacity: 0; }
      100% { opacity: 1; }
    }

  }

  .splide__arrows {
    display: flex;
    gap: pxToRem(80px);
    margin-block-start: pxToRem(45px);

    .splide__arrow {
      @include utopia.generateClamps((
        "minWidth": 1024,
        "maxWidth": 1440,
        "pairs": (
          (10, 14),
          (65, 91),
        ),
        "prefix": "fluid-",
      ));
      position: initial;
      opacity: 1;
      border: 1px solid var(--cn-c-page--BackgroundColor);
      background-color: var(--cn-c-page--BackgroundColor);
      color: var(--cn-c-page--Color);
      width: pxToRem(31px);
      height: pxToRem(31px);
      top: unset;
      bottom: pxToRem(64px);
      transform: none;

      svg {
        z-index: 1;
        width: pxToRem(20px);
        height: pxToRem(20px);
      }

      &:hover {
        background-color: var(--cn-c-page--BackgroundColor);
        border-color: var(--cn-c-page--BackgroundColor);
        color: var(--cn-c-page--Color);
      }

      &:disabled {
        background-color: transparent;
        color: var(--cn-global--palette-black--40);
      }

    }

    @media screen and (min-width: map.get($grid-breakpoints, md)) {
      position: absolute;
      left: 0;
      top: pxToRem(150px);
      margin-block-start: unset;
    }
  }

}
