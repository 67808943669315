/******************************************************************************
 * Ecommerce / Molecules / Price
 *****************************************************************************/

@use "~/src/styles/utilities.scss" as *;

.cn-c-price {
  display: flex;
  align-items: baseline;

  .cn-c-label.decimal {
    font-size: pxToRem(20px);
  }

  .cn-c-label.currency {
    font-size: pxToRem(22px);
    margin-left: pxToRem(2px);
  }
}
