/******************************************************************************
 * Ecommerce / Molecules / Accordion / Product Detail
 *****************************************************************************/

@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-accordion.cn-c-accordion--product_detail {

  input[name="cn-c-accordion__item"]:checked ~ .cn-c-accordion__item {
    &.cn-c-accordion-group__product_details {
        margin-block-end: pxToRem(25px);
    }
    &.cn-c-accordion-group__description{
        margin-block-end: pxToRem(10px);
    }
  }

  .cn-c-accordion-group__product_details {
    .product_details__item {
      display: flex;
      justify-content: space-between;
      padding-block-start: pxToRem(19px);
      padding-block-end: pxToRem(10px);

      &:not(:last-child) {
        border-block-end: 1px solid var(--cn-global--palette-black--10);
      }
    }
  }

  .cn-c-button--no-frame {
    --cn-c-accordion--Font-title: var(--cn-c-other_product_infos--Font-title);
  }
}
