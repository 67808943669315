/** ***************************************************************************
 * Ecommerce / Organisms / Products Slider
 *************************************************************************** */

 @use "sass:map";
@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-products_slider {
  --cn-c-products_slider--MarginBottom: #{pxToRem(45px)};

  .splide.homepage {

    > * {
      grid-column: 1 / -1;
    }

    .splide__track {
      margin-block-end: unset;
    }

    .splide__slide {
      height: calc(100vh - var(--cn-c-template_shared--top_nav--PositionY));
      display: grid;
      grid-template-columns: var(--cn-c-page--Grid-Template);
      grid-gap: 0 var(--cn-c-page--Grid-Gap);
      padding-inline: var(--cn-global--content-grid--offsetsize);
      align-items: end;
      color: var(--cn-c-page--BackgroundColor);

      .main-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: space-between;
        gap: pxToRem(24px);
        width: unset;
        grid-column: 1 / -1;
        margin-block-end: calc(var(--cn-c-products_slider--MarginBottom) + pxToRem(8px));

        > div:first-child {
          flex: 1;
        }

        .content-wrapper {
          overflow: hidden;
        }

        h1 {
          font-size: pxToRem(50px);
          line-height: pxToRem(52px);
          margin-block-end: pxToRem(17px);
          will-change: transform;
          transform: translateY(120%);
          transition: transform 500ms;
          transition-delay: 500ms;

          @media all and (min-width: map.get($grid-breakpoints, md)) {
            font-size: pxToRem(70px);
            line-height: pxToRem(64px);
          }
        }

        h2 {
          font-size: pxToRem(20px);
          font-weight: var(--cn-global--FontWeight-medium);
          letter-spacing: 0.34px;
          will-change: transform;
          transform: translateY(110%);
          transition: transform 500ms;
          transition-delay: 650ms;

          @media all and (min-width: map.get($grid-breakpoints, md)) {
            font-size: pxToRem(24px);
          }
        }

        .cn-c-button {
          --cn-c-button--BackgroundColor: var(--cn-c-page--AccentColor);
          --cn-c-button--hover--Color: var(--cn-c-page--Color);
          --cn-c-button--hover--BackgroundColor: var(--cn-c-page--BackgroundColor);
          margin-block-start: unset;
          will-change: transform;
          transform: translateY(110%);
          transition: transform 500ms;
          transition-delay: 650ms;
        }

        @media screen and (min-width: map.get($grid-breakpoints, md)) {
          grid-column: 2 / -2;
          text-align: unset;
          align-items: flex-start;
          margin-block-end: calc(var(--cn-c-products_slider--MarginBottom) + pxToRem(60px));
        }

        @media screen and (min-width: map.get($grid-breakpoints, lg)) {
          flex-direction: row;
          align-items: flex-end;
          margin-block-end: calc(var(--cn-c-products_slider--MarginBottom) + pxToRem(86px));
        }
      }

      .cn-c-image_wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        width: auto;
        height: auto;
        z-index: -1;

        .mask-on-image {
          display: block;
        }

        img {
          height: 100%;
          object-fit: cover;
          max-height: 100%;
        }
      }

      &.will-active,
      &.is-active {
        h1, h2, .cn-c-button {
          transform: translateY(0);
        }
      }
    }

    .splide__arrows {
      position: relative;
      bottom: var(--cn-c-products_slider--MarginBottom);

      .splide__arrow {
        bottom: pxToRem(50px);

        &--prev {
          left: 0;
        }
        &--next {
          right: 0;
        }
      }
    }

    .thumbnails {
      position: absolute;
      left: 0;
      right: 0;
      grid-column: 1 / -1;
      // bottom: calc(-1 * pxToRem(20px));
      bottom: 0;
      max-width: pxToRem(228px);
      color: var(--cn-c-page--BackgroundColor);
      border-block-color: var(--cn-global--palette-white--60);

      .thumbnail {
        height: pxToRem(64px);
      }

      .text {
        display: flex;
        align-items: baseline;
        gap: pxToRem(5px);

        .counter {
          margin-block-start: unset;
        }

        .cn-c-label.semibold {
          display: none;
          font-size: pxToRem(16px);

          @media screen and (min-width: map.get($grid-breakpoints, lg)) {
            display: block;
          }
        }
      }

      .my_splide__progress__bar {
        background: var(--cn-global--palette-white);
      }

      @media screen and (min-width: map.get($grid-breakpoints, sm)) {
        max-width: pxToRem(364px);
      }

      @media screen and (min-width: map.get($grid-breakpoints, md)) {
        grid-column: 2 / -2;
        bottom: var(--cn-c-products_slider--MarginBottom);
        margin: unset;
        max-width: unset;
      }
    }
  }
}
