/** ***************************************************************************
 * Ecommerce / Organisms / Cart Summary
 *************************************************************************** */

 @use "sass:map";
@use '~/src/styles/utilities.scss' as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-cart_summary {
  grid-column: 1 / -1;
  background-color: var(--cn-global--palette-white-smoke);
  padding-block: pxToRem(53px) pxToRem(37px);
  padding-inline: var(--cn-global--content-grid--offsetsize);
  border-radius: 4px;

  height: fit-content;
  position: sticky;
  top: pxToRem(50px);

  display: flex;
  flex-direction: column;
  gap: pxToRem(30px);

  .promotional-code {
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;

    &.is-open {
      text-decoration: none;
      cursor: default;
    }
  }

  h3 {
    margin-block: 0 !important;
  }

  form {
    display: none;

    &.is-open {
      display: flex;
      flex-direction: column;
      gap: pxToRem(15px);
    }

    input {
      margin-block-start: pxToRem(23px);
      padding-block: pxToRem(18px);
      padding-inline: pxToRem(20px);
      background-color: transparent;
      border: 1px solid var(--cn-c-page--Color);
      border-radius: 12px;

      &:focus {
        outline: none;
      }
    }

    .action-buttons {
      display: flex;
      align-items: center;
      gap: pxToRem(15px);

      .cancel {
        --cn-c-button--PaddingInline: 0;
        --cn-c-button--PaddingBlock: 0;
        --cn-c-button--no-frame--Color: var(--cn-c-page--Color);
        --cn-c-button--hover--Color: var(--cn-c-page--Color);
        font: var(--cn-global--Font-label--xs-regular);
        text-decoration: underline;
      }
    }
  }

  .cn-u-separator {
    height: 1px;
    background-color: var(--cn-c-page--Color);
  }

  .price-detail {
    display: flex;
    justify-content: space-between;
    gap: pxToRem(15px);

    .info {
      flex: 1;
    }

    .message {
      margin-block-start: pxToRem(10px);
    }
  }

  .cn-c-label a {
    text-decoration: underline;
  }

  .pill {
    font-size: pxToRem(11px);
    letter-spacing: normal;
    height: pxToRem(17px);
  }

  .entries {
    display: flex;
    flex-direction: column;
    gap: pxToRem(21px);
  }

  .terms {
    display: flex;
    flex-direction: column;
    gap: pxToRem(6px);
    opacity: .7;
  }

  @media screen and (min-width: map.get($grid-breakpoints, xl)) {
    grid-column: 8 / span 5;
    padding-inline: pxToRem(87px);
  }
}
