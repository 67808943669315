/** ***************************************************************************
 * Ecommerce / Organisms / Heading
 *************************************************************************** */

 @use "sass:map";
@use '~/src/styles/utilities.scss' as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-heading {
  --cn-c-heading__first_line--MarginBlockEnd: 1.875rem;  /* 30px */

  @media screen and (min-width: map.get($grid-breakpoints, md)) {
    --cn-c-heading__first_line--MarginBlockEnd: 2.9375rem;  /* 47px */
  }
}



.cn-c-heading {

  img {
    height: pxToRem(350px);

    @media screen and (min-width: map.get($grid-breakpoints, sm)) {
      height: pxToRem(477px);
    }

    @media screen and (min-width: map.get($grid-breakpoints, xxxl)) {
      height: pxToRem(580px);
    }
  }

  .first-line {
    margin-block-end: var(--cn-c-heading__first_line--MarginBlockEnd);
  }

  h1 {
    font-size: pxToRem(45px);
    line-height: pxToRem(50px);
    margin-block-end: unset;

    @media screen and (min-width: map.get($grid-breakpoints, sm)) {
      font-size: pxToRem(50px);
      line-height: pxToRem(54px);
    }

    @media screen and (min-width: map.get($grid-breakpoints, md)) {
      font-size: pxToRem(60px);
      line-height: pxToRem(64px);
    }

    @media screen and (min-width: map.get($grid-breakpoints, xxl)) {
      font-size: pxToRem(70px);
      line-height: pxToRem(74px);
    }
  }

  .first-line ~ * {
    font-size: pxToRem(18px);
    line-height: var(--cn-global--LineHeight-xs);
    font-weight: var(--cn-global--FontWeight-regular);

    @media screen and (min-width: map.get($grid-breakpoints, md)) {
      font-size: pxToRem(24px);
      line-height: var(--cn-global--LineHeight-md);
    }
  }

}
