/******************************************************************************
 * Ecommerce / Atoms / Email address
 *****************************************************************************/

.cn-c-email_address {
  /**
   * Description
   */

  --cn-c-email_address__description--FontSize: .9375em;
  --cn-c-email_address__description--FontWeight: var(--cn-global--FontWeight-semibold);
  --cn-c-email_address__description--MarginBlockEnd: .666em;
  --cn-c-email_address__description--LineHeight: 1.2;
}



/**
 * Component
 */

.cn-c-email_address {
  font-size: 1rem;
}



/**
 * Description
 */

.cn-c-email_address > div {
  margin-block-end: var(--cn-c-email_address__description--MarginBlockEnd);
  font-size: var(--cn-c-email_address__description--FontSize);
  font-weight: var(--cn-c-email_address__description--FontWeight);
  line-height: var(--cn-c-email_address__description--LineHeight);
}



/**
 * Link
 */

.cn-c-email_address > a {

}
