/** ***********************************************************************************
 * Atoms / Typography / Inline elements / Anchor
 *********************************************************************************** */

body {
  /**
   * Link
   */

  --cn-c-page__link--FontWeight: var(--cn-global--FontWeight-bold);
}



/**
 * Component
 */

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

p a {
  color: var(--cn-c-page--AccentColor);
  /* border-bottom: 1px solid; */
  font-weight: var(--cn-global--FontWeight-medium);
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
}
