/******************************************************************************
 * Ecommerce / Molecules / Cart Item
 *****************************************************************************/

 @use "sass:map";
@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-stepper {
  border-block: 1px solid var(--cn-global--palette-black--40);
  margin-block-end: pxToRem(24px);

  display: flex;
  flex-direction: column;
  gap: pxToRem(24px);
  row-gap: 5px;

  .step {
    --cn-c-button--PaddingBlock: #{pxToRem(14px)};
    --cn-c-button--PaddingInline: unset;
    --cn-c-button--no-frame--Color: var(--cn-c-page--Color);
    --cn-c-button--hover--Color: var(--cn-c-button--no-frame--Color);

    font: var(--cn-global--Font-label--sm-semibold);
    opacity: .5;

    &.is-disabled,
    &.is-current {
      pointer-events: none;
    }

    &.is-active {
      opacity: 1;
    }

    &.is-current {

      span {
        position: relative;

        &::after {
          content: '';
          display: block;
          // width: 100%;
          height: 3px;
          background-color: var(--cn-c-page--Color);
          border-radius: 3px;
          position: absolute;
          bottom: -13px;
          left: 0;
          right: 0;

          @media screen and (min-width: map.get($grid-breakpoints, md)) {
            bottom: -29px;
          }
        }
      }
    }

    @media screen and (min-width: map.get($grid-breakpoints, md)) {
      --cn-c-button--PaddingBlock: #{pxToRem(27px)};
    }
  }

  @media screen and (min-width: map.get($grid-breakpoints, md)) {
    flex-direction: row;
    justify-content: space-between;
    gap: pxToRem(32px);
    row-gap: 11px;
    margin-block-end: pxToRem(69px);
  }
}
