/** ***************************************************************************
 * Ecommerce / Organisms / Auth / Header
 *************************************************************************** */

@use 'utopia-core-scss/src/utopia' as utopia;

header.cn-c-auth-header {
  @include utopia.generateClamps((
    "minWidth": 678,
    "maxWidth": 1440,
    "pairs": (
      (60, 84),
    ),
    "prefix": "fluid-",
  ));
  --cn-c-auth-header__logo--Height: var(--fluid-60-84);
  --cn-c-auth-header__hamburger--Width: 1.625rem;  /* 26px */

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media all and (min-width: 48rem) {
    align-items: flex-start;
  }


  /**
    * Logo
    */
  > a svg {
    height: var(--cn-c-auth-header__logo--Height);
  }


  /**
    * Hamburger
    */
  > label {
    width: var(--cn-c-auth-header__hamburger--Width);

    @media all and (min-width: 48rem) {
      display: none;
    }
  }


  /**
    * Navigation
    */
  > .cn-c-navigation {
    z-index: 999;
  }

}
