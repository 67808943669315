/** ***************************************************************************
 * Ecommerce / Organisms / Auth / Navigation
 *************************************************************************** */

@use 'utopia-core-scss/src/utopia' as utopia;

.cn-c-auth-nav {
  --cn-c-auth-nav--BackgroundColor: var(--cn-global--palette-dusty-blue--40);
  --cn-c-auth-nav--ColumnGap: 2.75em;  /* 55px */
  --cn-c-auth-nav--Font: var(--cn-global--Font-title--lg-bold);
  --cn-c-auth-nav--PaddingBlock: 3.5rem;  /* 56px, eyeballed */

  /**
    * Close button
    */

  --cn-c-auth-nav__close--BorderColor: var(--cn-global--palette-white--49);
  --cn-c-auth-nav__close--Size: 1rem;  /* 16px */


  /**
    * Navigation items
    */

  --cn-c-auth-nav__items--MarginBlockStart: 1.5rem;
  --cn-c-auth-nav__language--MarginBlockStart: 2.5rem;  /* 40px */
  --cn-c-auth-nav__items--RowGap: 1.5rem;  /* 24px */


  /**
    * auth-nav item
    */

  --cn-c-auth-nav__item--Color: var(--cn-global--palette-dark-blue);
  --cn-c-auth-nav__item--RowGap: .65em;  /* 13px */

  --cn-c-auth-nav__item-decoration--BackgroundColor: currentcolor;

  --cn-c-auth-nav__item--m-current--Color: var(--cn-global--palette-white);

  /**
    * Logo
    */

  --cn-c-auth-nav__logo--Height: 3.813rem;  /* 61px */


  @media all and (min-width: 48rem) {
    --cn-c-auth-nav--BackgroundColor: transparent;
    --cn-c-auth-nav--Font: var(--cn-global--Font-title--md-regular);
  }

  @media all and (min-width: 48rem) {
    /* --cn-c-auth-nav__item--Color: var(--cn-global--palette-white); */
    --cn-c-auth-nav__item--m-current--Color: var(--cn-c-auth-nav__item--Color);
    --cn-c-auth-nav__item--RowGap: .5em;  /* 7px */
    --cn-c-auth-nav__item-decoration--BackgroundColor: var(--cn-global--palette-dark-blue);
  }
}


#cn-c-auth-header__toggle:not(:checked) ~ .cn-c-auth-nav {
  opacity: 0;
  pointer-events: none;
  transition: none;

  @media all and (min-width: 48rem) {
    opacity: 1;
    pointer-events: initial;
  }
}

.cn-c-auth-nav {
  background-color: var(--cn-c-auth-nav--BackgroundColor);
  color: var(--cn-global--palette-dark-blue);
  display: grid;
  font: var(--cn-c-auth-nav--Font);
  grid-template-rows: 4fr auto 10fr;
  inset: 0;
  overflow: auto;
  padding-block: var(--cn-c-auth-nav--PaddingBlock);
  place-items: center;
  position: fixed;
  text-align: center;
  transition: opacity .15s linear;
  z-index: 9;

  @media all and (min-width: 48rem) {
    column-gap: var(--cn-c-auth-nav--ColumnGap);
    display: flex;
    flex-direction: row;
    padding-block: 0;
    position: static;
  }

  @supports not (inset: 0) {
    bottom: 0;
    left: 0;
    padding-top: var(--cn-c-auth-nav--PaddingBlock);
    right: 0;
    top: 0;

    @media all and (min-width: 48rem) {
      padding-top: 0
    }
  }


  /**
    * Close button
    * 1: reset
    */
  > label {
    align-self: end;
    border-radius: 50%;
    /* border: 1px solid var(--cn-c-auth-nav__close--BorderColor); */
    box-sizing: content-box;  /* 1 */
    display: inline-block;
    /* height: var(--cn-c-auth-nav__close--Size); */
    padding: calc(var(--cn-c-auth-nav__close--Size) * 1.125);
    /* width: var(--cn-c-auth-nav__close--Size); */

    .cn-c-button.close-button {
      --cn-c-button--m-close--Background-Color: transparent;
      --cn-c-button--m-close--Color: var(--cn-global--palette-dark-grey);
      border-color: var(--cn-global--palette-dark-grey--49);
      padding: .93rem;
      border-radius: 50%;
      pointer-events: none;

      svg {
        width: 1.2rem;
      }
    }

    svg {
      display: block;  /* 1 */
    }

    @media all and (min-width: 48rem) {
      display: none;
    }
  }


  .content-wrapper {
    @include utopia.generateClamps((
      "minWidth": 768,
      "maxWidth": 1440,
      "pairs": (
        (10, 13),
        (13, 18),
      ),
      "prefix": "fluid-",
    ));

    @media all and (min-width: 48rem) {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  /**
    * auth-nav items
    * 1: reset
    */
  ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;  /* 1 */
    margin-block: var(--cn-c-auth-nav__items--MarginBlockStart) 0;
    margin-inline: 0;  /* 1 */
    padding: 0;  /* 1 */
    gap: var(--cn-c-auth-nav__items--RowGap);

    @media all and (min-width: 48rem) {
      flex-direction: row;
      gap: var(--fluid-10-13);
      margin-block: 0;
      padding-inline-start: var(--fluid-13-18);
    }

    @supports not (margin-block: 0 0) {
      margin: var(--cn-c-auth-nav__items--MarginBlockStart) 0 0 0;
    }
  }


  /**
    * auth-nav item
    * 1: reset
    * 2: contain decoration
    */
  li {
    color: var(--cn-c-auth-nav__item--Color);

    &.cn-m-current {
      color: var(--cn-c-auth-nav__item--m-current--Color);
      pointer-events: none;

      @media all and (min-width: 48rem) {
        a::after {
          transform: scaleX(1);
        }
      }
    }

    &:first-child a {
      background-color: var(--cn-global--palette-light-blue);
      color: var(--cn-global--palette-white);
    }

    a {
      display: inline-block;
      border-color: var(--cn-global--palette-light-blue);
      font-size: 1.125rem;
      color: var(--cn-c-auth-nav__item--Color);

      @supports not (padding-block-end: 0) {
        padding-bottom: var(--cn-c-auth-nav__item--RowGap);
      }

      &:hover {
        background-color: var(--cn-global-common--palette-kiss);
        border-color: var(--cn-global-common--palette-kiss);
      }

      @media all and (min-width: 48rem) {
        font: var(--cn-global--Font-label--md-medium);
      }
    }
  }


  /**
    * Logo
    */

  > svg {
    height: var(--cn-c-auth-nav__logo--Height);
    display: inline-block;
    align-self: end;

    @media all and (min-width: 48rem) {
      display: none;
    }
  }
}
