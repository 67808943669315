/** ***********************************************************************************
 * Ecommerce / Atoms / Typography / Label
 *********************************************************************************** */

.cn-c-label {
  font: var(--cn-global--Font-label--xs-regular);

  &.small {
    font: var(--cn-global--Font-label--sm-regular);
  }

  &.book {
    font: var(--cn-global--Font-label--xs-book);

    &.small {
      font: var(--cn-global--Font-label--sm-book);
      line-height: var(--cn-global--LineHeight-sm);
    }
  }

  &.semibold {
    font: var(--cn-global--Font-label--xs-semibold);

    &.small {
      font: var(--cn-global--Font-label--sm-semibold);
    }

    &.medium {
      font: var(--cn-global--Font-label--md-semibold);
    }

    &.large {
      font: var(--cn-global--Font-label--lg-semibold);
    }

    &.extralarge {
      font: var(--cn-global--Font-label--xl-semibold);
      line-height: var(--cn-global--LineHeight-sm);
    }
  }

  &.medium {
    font: var(--cn-global--Font-label--md-medium);
  }

  &.bold {
    font: var(--cn-global--Font-label--md-bold);
  }

}
