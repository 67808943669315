@use "sass:string";
@use "../utilities.scss" as *;

$grid-breakpoints: (
  xs: 0,
  sm: pxToRem(600px),
  md: pxToRem(768px),
  lg: pxToRem(1024px),
  xl: pxToRem(1280px),
  xxl: pxToRem(1440px),
  xxxl: pxToRem(1920px)
);

/* This is useful to use variables in JS files */
:export {
  @each $key, $value in $grid-breakpoints {
    #{string.unquote($key)}: $value;
  }
}
