/******************************************************************************
 * Ecommerce / Molecules / Accordion
 *****************************************************************************/

@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-accordion {
  margin:0;
  padding: 0;

  .accordion-group {
    display: flex;
    flex-direction: column;
    border-block-end: var(--cn-global--border);
    padding: unset;
  }

  .cn-c-accordion__item {
    max-height: 0;
    overflow: hidden;
    transition: max-height var(--cn-global--Duration--sm) ease;
    margin-inline: pxToRem(13px);

    p {
      font: var(--cn-global--Font-paragraph--lg-regular);
      line-height: var(--cn-global--LineHeight-sm);

      b, strong {
        font-weight: var(--cn-global--FontWeight-semibold);
      }
    }

    ul {
      list-style-type: disc;
      padding-inline-start: pxToRem(21px);

      > li {
        padding-inline-start: pxToRem(9px);
      }
    }

    :is(h1, h2, h3, h4) {
      font: var(--cn-global--Font-title--md-semibold);
      line-height: var(--cn-global--LineHeight-xs);
      margin-block-end: pxToRem(14px);
      margin-block-start: 0;
    }
  }

  input[name="cn-c-accordion__item"]:checked ~ .cn-c-accordion__item {
    max-height: 500px;
  }

  .cn-c-button--no-frame {
    --cn-c-button--no-frame--Color: var(--cn-c-page--Color);
    --cn-c-button--hover--Color: var(--cn-c-page--Color);
    --cn-c-button--PaddingBlock: #{pxToRem(15px)};
    --cn-c-button--PaddingInline: #{pxToRem(13px)};

    font: var(--cn-c-accordion--Font-title);
    letter-spacing: 0.33px;
    width: 100%;
    justify-content: space-between;

    > .label {
      pointer-events: none;
    }
  }
}
