/******************************************************************************
 * Ecommerce / Molecules / Product Card
 *****************************************************************************/

@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-product_card {

  .cn-c-label.book.author {
    font-family: "PPNeueMontreal Book Italic";
    font-style: italic;
    font-size: pxToRem(15px);
  }

  .cn-c-price_wrapper {
    .period {
      font-size: pxToRem(12px);
      line-height: pxToRem(22px);
    }
  }

  .cn-c-price {
    position: relative;

    &:nth-child(1):not(:only-child) {
      &::before {
        height: 1px;
      }
    }

    .cn-c-label.semibold.extralarge {
      font-size: pxToRem(16px);
    }

    .cn-c-label.decimal {
      font-size: pxToRem(11px);
    }

    .cn-c-label.currency {
      font-size: pxToRem(12px);
    }
  }

  .isbn-wrapper {
    display: flex;
    align-items: baseline;
    gap: pxToRem(3px);
    margin-block: pxToRem(13px) pxToRem(15px);
  }

  .cn-c-add_to_cart_cta {
    --cn-c-add_to_cart--Color: var(--cn-c-page--Color);
    background-color: var(--cn-c-page--BackgroundColor);
    width: unset;
    border-radius: pxToRem(4px);
    margin-block-start: pxToRem(9px);
    height: pxToRem(41px);
    padding-block: pxToRem(8px);
    padding-inline-start: pxToRem(11px);
    padding-inline-end: unset;
    box-shadow: var(--cn-global--BoxShadow--CardButton);
    // justify-content: space-around;

    .cn-c-button {
      font-size: pxToRem(14px);
      // padding-inline: unset;
    }

    .vertical-separator {
      background-color: var(--cn-global--border--Color);
    }

    &:hover {
      --cn-c-add_to_cart--Color: var(--cn-c-page--BackgroundColor);
      background-color: var(--cn-c-page--AccentColor);

      .vertical-separator {
        background-color: var(--cn-global--border--Color--DarkTheme);
      }
    }
  }
}
