/** ***************************************************************************
 * Molecules / Slider / Slider
 *************************************************************************** */

 @use 'utopia-core-scss/src/utopia' as utopia;
 @use "~/src/styles/utilities.scss" as *;
 @import '@splidejs/splide/dist/css/splide.min.css';

.splide {
  @include utopia.generateClamp((
    "minWidth": 375,
    "maxWidth": 1440,
    "minSize": 45,
    "maxSize": 85,
    "prefix": "fluid-",
  ));
  --splide-items-gap: var(--fluid-45-85);

  &__track {
    overflow-y: visible;
    overflow-x: clip;
    margin-block-end: pxToRem(52px);
  }

  &__list {
    align-items: center;
  }

  &__slide {
    background-color: var(--cn-global--palette-black--03);
    border-radius: 4px;
    padding-block: pxToRem(33px);
    padding-inline: pxToRem(30px);

    .main-content {
      width: pxToRem(296px);

      h1 {
        font: var(--cn-global--Font-title--sm-semibold);
        line-height: normal;
        margin-block-end: pxToRem(3px);
      }

      h2 {
        font-size: pxToRem(15px);
        font-weight: var(--cn-global--FontWeight-regular);
        line-height: normal;
        margin-block-end: pxToRem(7px);
      }

      .cn-c-button {
        --cn-c-button--PaddingBlock: 0.723em;
        --cn-c-button--PaddingInline: 1.515em;
        --cn-c-button--BackgroundColor: var(--cn-c-page--Color);
        --cn-c-button--hover--BackgroundColor: var(--cn-c-page--AccentColor);
        font-size: pxToRem(16px);
        font-weight: var(--cn-global--FontWeight-semibold);
        display: inline-flex;
        margin-block-start: pxToRem(19px);
      }

      .cn-c-price_wrapper {
        .period {
          font-size: pxToRem(12px);
          line-height: pxToRem(22px);
        }
      }

      .cn-c-price {
        position: relative;

        &:nth-child(1):not(:only-child) {
          &::before {
            height: 1px;
          }
        }

        .cn-c-label.semibold.extralarge {
          font-size: pxToRem(16px);
        }

        .cn-c-label.decimal {
          font-size: pxToRem(11px);
        }

        .cn-c-label.currency {
          font-size: pxToRem(12px);
        }
      }

      .cn-c-variant {
        margin-block-start: pxToRem(12px);

        .options {
          border-block: none;
          gap: pxToRem(7px);
          padding-block: 0;

          .cn-c-button {
            --cn-c-button--PaddingInline: #{pxToRem(10px)};
            font-size: pxToRem(11px);
            letter-spacing: 0;
            height: pxToRem(17px);
          }
        }
      }
    }

    .cn-c-image_wrapper {
      position: absolute;
      bottom: 0;
      right: pxToRem(91px);
      display: flex;
      align-items: flex-end;
      justify-content: center;
      width: pxToRem(569px);
      height: pxToRem(395px);

      .mask-on-image {
        display: none;
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    @keyframes fadeIn {
      0% { opacity: 0; }
      100% { opacity: 1; }
    }

  }

  &__arrows {
    // display: none;

    .splide__arrow {
      @include utopia.generateClamps((
        "minWidth": 1024,
        "maxWidth": 1440,
        "pairs": (
          (10, 14),
          (65, 91),
        ),
        "prefix": "fluid-",
      ));
      opacity: 1;
      border: 1px solid var(--cn-c-page--AccentColor);
      background-color: var(--cn-c-page--AccentColor);
      color: var(--cn-c-page--BackgroundColor);
      width: pxToRem(31px);
      height: pxToRem(31px);
      top: unset;
      bottom: pxToRem(64px);
      transform: none;

      svg {
        z-index: 1;
        width: pxToRem(20px);
        height: pxToRem(20px);
      }

      &--prev {
        left: calc(-1 * pxToRem(111px));
      }

      &--next {
        right: calc(-1 * pxToRem(111px));
      }

      &:hover {
        background-color: var(--cn-c-page--BackgroundColor);
        border-color: var(--cn-c-page--BackgroundColor);
        color: var(--cn-c-page--Color);
      }

      &:disabled {
        background-color: transparent;
        color: var(--cn-c-page--AccentColor);
      }

    }

    @media (min-width: 64rem) {
      display: block;
    }
  }

  .splide__pagination {
    @include utopia.generateClamps((
      "minWidth": 1024,
      "maxWidth": 1440,
      "pairs": (
        (10, 14),
        (17, 24),
        (26, 36),
      ),
      "prefix": "fluid-",
    ));
    counter-reset: pagination-num;
    background-color: transparent;
    position: relative;
    margin-block-start: var(--fluid-26-36);

    &__page {
      color: currentColor;
      background-color: transparent;
      opacity: 1;
      width: var(--fluid-17-24);
      height: var(--fluid-17-24);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: none;

      &.is-active {
        color: var(--cn-global--palette-white);
        background-color: var(--cn-c-page--AccentColor);
        transform: none;
      }

      &:before {
        counter-increment: pagination-num;
        content: counter( pagination-num );
        font-size: var(--fluid-10-14);
      }

      &:hover {
        color: var(--cn-global--palette-white);
        background-color: var(--cn-c-page--AccentColor);
      }
    }
  }

  .my_splide__progress__bar {
    position: absolute;
    top: -2px;
    height: 3px;
    background: var(--cn-c-page--Color);
    border-radius: 3px;
  }

  .thumbnails {
    display: flex;
    margin: 1rem auto 0;
    padding: 0;
    justify-content: center;
    border-block-start: 1px solid var(--cn-global--palette-black--60);

    .thumbnail {
      position: relative;
      flex: 1;
      height: pxToRem(80px);
      list-style: none;
      margin: 0;
      cursor: pointer;
      padding-block-start: pxToRem(25px);
    }

    .thumbnail img {
      width: 100%;
      height: auto;
    }

    .counter {
      margin-block-start: pxToRem(10px);
    }
  }

  // &.is-focus-in .splide__arrow:focus {
  //   outline: var(--ap-global--Outline--black);
  // }

}
