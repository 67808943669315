/******************************************************************************
 * Ecommerce / Molecules / iClimbing CTA
 *****************************************************************************/

@use "~/src/styles/utilities.scss" as *;

.cn-c-iClimbing_cta {
  display: flex;
  align-items: center;
  gap: pxToRem(14px);

  > p {
    margin: 0;
    flex: 1;
  }

  > img {
    block-size: 2.75rem;
    inline-size: auto;
  }
}
