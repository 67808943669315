/** ***************************************************************************
 * Ecommerce / Organisms / Channels
 *************************************************************************** */

 @use "sass:map";
@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-channels {

  > .container {
    display: flex;
    flex-direction: column;
    gap: pxToRem(50px);
    justify-content: space-evenly;

    > * {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-basis: 33%;
      gap: pxToRem(18px);

      &:hover {
        .arrow-button {
          opacity: 1;
        }
      }
    }

    img {
      block-size: 5rem;
    }

    .cn-c-label {
      text-align: center;
      letter-spacing: 0.22857142984867096px;

      &.semibold {
        font-size: pxToRem(22px);
        letter-spacing: 0.3142857253551483px;
      }
    }

    .arrow-button {
      --cn-c-button--icon-only--Dimension: #{pxToRem(31px)};
      --cn-c-button--icon-only--Padding: #{pxToRem(5px)};
      opacity: 0;
      transition: opacity var(--cn-global--Duration--xs) ease-in-out;
      display: none;

      svg {
        block-size: pxToRem(20px);
      }

      @media screen and (min-width: map.get($grid-breakpoints, md)) {
        display: block;
      }
    }

    @media screen and (min-width: map.get($grid-breakpoints, md)) {
      flex-direction: row;
    }
  }
}
