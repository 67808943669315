/******************************************************************************
 * Ecommerce / Molecules / Breadcrumb
 *****************************************************************************/

 @use "sass:map";
@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-breadcrumb {
  --cn-c-button--no-frame--Color: var(--cn-c-page--Color);

  display: flex;
  align-items: center;

  > .cn-c-button {
    --cn-c-button--hover--Color: var(--cn-c-button--no-frame--Color);
    --cn-c-button--hover--BackgroundColor: unset;
    --cn-c-button--icon-only--Dimension: unset;
    justify-content: flex-start;
    padding: 0;

    &:first-child {
      margin-inline-end: pxToRem(9px);

      svg {
        transform: rotate(180deg);
      }
    }

    &.is-last {
      --cn-c-button--no-frame--Color: var(--cn-c-page--AccentColor);
    }
  }

  .crumb-separator {
    margin-inline: pxToRem(2px);
  }

  @media all and (min-width: map.get($grid-breakpoints, xl)) {
    > .cn-c-button:first-child {
      display: none;
    }

    a.cn-c-button {
      font-size: pxToRem(18px);
      border-block-end: 1px solid var(--cn-c-button--no-frame--Color);

      &.is-last {
        --cn-c-button--no-frame--Color: unset;
      }
    }

    .crumb-separator {
      font-size: pxToRem(18px);
      margin-inline: pxToRem(7px);
    }
  }
}
