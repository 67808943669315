/** ***************************************************************************
 * Ecommerce / Organisms / CMS plugins / Contact information
 *************************************************************************** */

 @use "sass:map";
@use "~/src/styles/shared/variables.module.scss" as *;
@use '~/src/styles/utilities.scss' as *;

.cn-c-contact_info {
  padding-block: pxToRem(42px);
  margin-block-end: pxToRem(40px);

  &.card {
    background-color: var(--cn-global--palette-white-smoke);
    border-radius: 4px;
  }

  > div {
    grid-column: 1 / -1;
    padding-inline: var(--cn-global--content-grid--offsetsize);

    @media screen and (min-width: map.get($grid-breakpoints, md)) {
      grid-column: 2 / -2;
      padding-inline: unset;
    }
  }

  h2 {
    grid-column: 1 / -1;
  }

  .socials-line {
    grid-column: 1 / -1;
    display: flex;
    gap: pxToRem(25px);
  }

  .text-line {
    grid-column: 1 / -1;
    margin-block-start: pxToRem(39px);
  }

  .columns-line {
    grid-column: 1 / -1;
    grid-row-gap: pxToRem(44px);
    margin-block-start: pxToRem(62px);

    /**
     * Column title
     * 1: simulate H4
     */

    h3 {
      font: var(--cn-global--Font-title--md-semibold);  /* 1 */
      line-height: var(--cn-global--LineHeight-xs);  /* 1 */
      margin-block-end: pxToRem(14px);  /* 1 */
      margin-block-end: pxToRem(19px);
      margin-block-start: 0;  /* 1 */
    }

    > div {
      grid-column: 1 / -1;

      @media screen and (min-width: map.get($grid-breakpoints, sm)) {
        grid-column: span 6;
      }

      @media screen and (min-width: map.get($grid-breakpoints, xl)) {
        grid-column: span 3;
      }
    }

    .address-button {
      font-size: pxToRem(13px);
      margin-block-start: pxToRem(19px);
    }

    .cn-c-email_address:nth-of-type(n+2) {
      margin-block-start: pxToRem(27px);
    }

    a:not(.cn-c-button) {
      text-decoration: underline;
      font-size: pxToRem(16px);
      line-height: 1.563;
    }
  }

  @media screen and (min-width: map.get($grid-breakpoints, lg)) {
    padding-block: pxToRem(72px);
    margin-block-end: pxToRem(180px);
  }
}
