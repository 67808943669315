/******************************************************************************
 * Ecommerce / Atoms / Button / Navigation
 *****************************************************************************/

.cn-c-button.cn-m-navigation {
  --cn-c-button--BackgroundColor: transparent;
  --cn-c-button--BorderRadius: unset;
  --cn-c-button--Color: var(--cn-global--palette-sky-blue);
  --cn-c-button--Font: var(--cn-global--Font-label--xs-medium);
  --cn-c-button--PaddingBlock: .625rem;  /* 10px */
  --cn-c-button--PaddingInline: 1rem;  /* 16px */
  // --cn-c-button--Border: var(--cn-global--Border--white-70);

  --cn-c-button--hover--BackgroundColor: var(--cn-c-button--m-navigation--hover--BackgroundColor);
  --cn-c-button--hover--Color: var(--cn-c-button--m-navigation--hover--Color);
  --cn-c-button--hover--Border: var(--cn-global--Border--white);

  --cn-c-nav--Inline-Gap: .5rem;

  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: var(--cn-c-nav--Inline-Gap);
  position: relative;
  width: 100%;

  > svg {
    width: 1rem; // 16px
  }

  &:hover {
    --cn-c-button--Color: var(--cn-global--palette-white);

    > svg {
      color: var(--cn-global--palette-bright-light-blue);
    }
  }

  &::after {
    border-bottom: 2px solid var(--cn-global--palette-bright-light-blue);
    border-radius: 10px;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    transform-origin: center;
    transform: scaleX(0);
    transition: transform var(--cn-global--Duration--tn) linear;
    width: .938rem;
  }

  &:active {
    --cn-c-button--Color: var(--cn-global--palette-white);

    &::after {
      transform: scaleX(1);
    }

    > svg {
      color: var(--cn-global--palette-bright-light-blue);
    }
  }
}
