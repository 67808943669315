/******************************************************************************
 * Ecommerce / Molecules / Address Card
 *****************************************************************************/

 @use "sass:map";
@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-address_card {
  background-color: var(--cn-global--palette-white-smoke);
  padding-block: pxToRem(33px);
  padding-inline: pxToRem(26px);
  border-radius: 4px;
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: pxToRem(14px);

  .action-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: pxToRem(15px);
    margin-block-start: pxToRem(16px);
    text-align: center;

    .cn-c-button--trash {
      flex-shrink: 0;
    }
  }

  @media screen and (min-width: map.get($grid-breakpoints, md)) {
    padding-block: pxToRem(53px);
    padding-inline: pxToRem(46px);

    .action-buttons {
      flex-direction: row;
      align-items: center;
    }
  }

  @media screen and (min-width: map.get($grid-breakpoints, lg)) {
    grid-column: span 6;
  }
}
