/** ***************************************************************************
 * Ecommerce / Organisms / Payment Method
 *************************************************************************** */

@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-payment_method {
  .form-fields {
    display: flex;
    flex-direction: column;
    gap: pxToRem(18px);
    align-items: flex-start;
    margin-block-end: pxToRem(40px);
  }

  .cn-c-button[type="submit"] {
    grid-column: 1 / -1;
  }
}
