/******************************************************************************
 * Atoms / List
 *****************************************************************************/

@use "~/src/styles/utilities.scss" as *;

ol, ul {
  padding-inline-start: 0;
  margin-block-end: pxToRem(25px);
}

ol {
  counter-reset: item;
  list-style-type: none;
}

ol > li {
  display: flex;
  gap: 20px;

  &::before {
    content: counter(item) " ";
    counter-increment: item;
    font-size: pxToRem(16px);
    font-weight: var(--cn-global--FontWeight-semibold);
    flex-basis: pxToRem(9px);
  }
}

// ul {
//   list-style-type: disc;
//   padding-inline-start: pxToRem(21px);

//   > li {
//     padding-inline-start: pxToRem(9px);
//   }
// }

li {
  line-height: var(--cn-global--LineHeight-sm);

  &::marker {
    --cn-c-list__marker--Color: var(--cn-c-page--Color);

    color: var(--cn-c-list__marker--Color, var(--cn-c-page--AccentColor));
  }
}
