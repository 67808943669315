/******************************************************************************
 * Ecommerce / Molecules / Accordion / CMS Page
 *****************************************************************************/

 @use "sass:map";
@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-accordion.cn-c-accordion--cms_page {


  .accordion-group {
    border-block-end: unset;
    display: flex;
    flex-direction: row;
    gap: pxToRem(16px);

    > div {
      grid-column: span 11;
      border-block-end: var(--cn-global--border);
    }

    @media screen and (min-width: map.get($grid-breakpoints, lg)) {
      display: grid;
      grid-template-columns: var(--cn-c-page--Grid-Template);
      grid-gap: 0 var(--cn-c-page--Grid-Gap);
    }
  }

  .cn-c-button.plus {
    --cn-c-button--icon-only--Padding: #{pxToRem(3px)};
    margin-block: 2.125rem;
    background-color: transparent;
    color: var(--cn-c-page--Color);
    border: 2px solid var(--cn-c-page--Color);
    block-size: pxToRem(30px);
    transition: all var(--cn-global--Duration--xs) ease;

    &.hoverized {
      background-color: var(--cn-c-page--AccentColor);
      color: var(--cn-c-page--BackgroundColor);
      border-color: var(--cn-c-page--AccentColor);
    }
  }

  .accordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: pxToRem(16px);

    .cn-c-button--outline {
      font-size: pxToRem(11px);
      letter-spacing: 0;
      height: pxToRem(17px);
    }
  }

  .cn-c-button--no-frame {
    --cn-c-accordion--Font-title: var(--cn-global--FontWeight-semibold) #{pxToRem(24px)}/#{pxToRem(30px)} var(--cn-global--FontFamily-default);
    --cn-c-button--PaddingBlock: #{pxToRem(34px)};
    --cn-c-button--PaddingInline: unset;
    --cn-c-button--hover--Color: var(--cn-c-page--AccentColor);
  }

  .cn-c-accordion__item {
    margin-inline: unset;
  }

  input[name="cn-c-accordion__item"]:checked ~ div {
    padding-block-end: pxToRem(16px);
  }
}
