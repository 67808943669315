/** ***************************************************************************
 * Ecommerce / Organisms / Cart List
 *************************************************************************** */

 @use "sass:map";
@use '~/src/styles/utilities.scss' as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-cart_list {
  grid-column: 1 / -1;
  padding-inline: var(--cn-global--content-grid--offsetsize);
  margin-block-end: pxToRem(27px);

  .cn-c-cart_item:first-child {
    padding-block-start: unset;
  }

  @media screen and (min-width: map.get($grid-breakpoints, xl)) {
    grid-column: 1 / span 7;
    padding-inline: unset;
    margin-block-end: unset;
  }
}
