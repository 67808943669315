/** ***************************************************************************
 * Ecommerce / Organisms / Site navigation
 *************************************************************************** */

@mixin custom-left-border {
  @media (orientation: portrait) {
    > div {
      position: relative;
      width: 100%;

      .cn-c-button.cn-m-navigation {
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 10px;
        border-left: 1px solid rgba(216, 216, 216, 0.36);

        &:after {
          bottom: -.625rem;
        }
      }
    }
  }
}

.cn-c-nav {
  --cn-c-nav--BackgroundColor: var(--cn-global--palette-dark-blue);
  --cn-c-nav--PaddingInlineEnd: .625rem;  /* 10px */
  --cn-c-nav--PaddingInlineStart: 1.563rem;  /* 25px */

  --cn-c-nav--PaddingBlock: .625rem;  /* 10px */
  --cn-c-nav--PaddingBlock--landscape: .938rem;  /* 15px */

  --cn-c-nav--PaddingInline--landscape: .938rem;  /* 15px */
}


/**
 * Component - default view PORTRAIT
 */

.cn-c-nav {
  align-items: center;
  background-color: var(--cn-c-nav--BackgroundColor);
  display: flex;
  height: var(--cn-c-nav--Height);
  justify-content: space-between;
  padding-block: var(--cn-c-nav--PaddingBlock);
  padding-inline: var(--cn-c-nav--PaddingInlineStart) var(--cn-c-nav--PaddingInlineEnd);
  width: auto;

  .logo {
    width: 5rem; // 80px

    > svg {
      vertical-align: middle;
    }

    @media (orientation: landscape) {
      display: none;

      @media (max-width: 50rem) {
        display: block;
        padding: 1.5rem 0.375rem;
        width: 100%;
      }
    }
  }

  .nav-items {
    // gap: 1.875rem;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex: 1 1 0;
    height: 100%;
    justify-content: flex-end;
    max-width: 60%;

    .position-start {
      // gap: 1.875rem;
      align-items: center;
      display: flex;
      flex-direction: row;
      flex: 2 1 0;
      width: 100%; //?

      @include custom-left-border;

      @media (orientation: landscape) {
        flex-direction: column;
        flex: unset;
        gap: unset;

        > div:before {
            content: unset;
        }

        > div:not(:first-child) {
          border-top: 1px solid rgba(216, 216, 216, .36);
          margin-top: 1.125rem;
          padding-top: .5rem;
          width: 100%;
        }
      }
    }

    .position-end {
      flex: 1 1 0;
      @include custom-left-border;
    }

    @media (orientation: landscape) {
      flex-direction: column;
      flex: unset;
      max-width: none;
      justify-content: space-between;

      .position-end {
        flex: unset;
        border-top: 1px solid var(--cn-global--palette-dusty-blue--51);
        width: 100%;

        > div:before {
          content: unset;
        }

        @media (max-width: 50rem) {
          border-top: none;
          padding-top: 0;

          > div {
            border-top: 1px solid var(--cn-global--palette-dusty-blue--51);
            padding-top: .625rem;
          }
        }
      }

      @media (max-width: 50rem) {
        max-width: 100%;
        height: unset;
      }
    }
  }

  @media (orientation: landscape) {
    display: block;
    width: var(--cn-c-page__navigation--sm--InlineSize);  // 68px
    height: 100%;
    padding-block: unset;
    padding-inline: unset;

    .position-start,
    .position-end {
      padding-block: var(--cn-c-nav--PaddingBlock--landscape);
      padding-inline: var(--cn-c-nav--PaddingInline--landscape);
    }

    @media (max-width: 50rem) {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}



/**
 * Top button
 */

#sm-c-app-status__map:checked ~ .cn-c-nav .cn-c-button[for="sm-c-app-status__map"],
#sm-c-app-status__roadmap:checked  ~ .cn-c-nav .cn-c-button[for="sm-c-app-status__roadmap"],
#sm-c-app-status__profile:checked  ~ .cn-c-nav .cn-c-button[for="sm-c-app-status__profile"] {
  --cn-c-button--Color: var(--cn-global--palette-white);

  &::after {
    transform: scaleX(1);
  }

  > svg {
    color: var(--cn-global--palette-bright-light-blue);
  }
}
