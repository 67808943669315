/** ***************************************************************************
 * Ecommerce / Organisms / Catalog CTA
 *************************************************************************** */

 @use "sass:map";
@use '~/src/styles/utilities.scss' as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-catalog_cta {
  --cn-c-catalog_cta--BackgroundColor: var(--cn-c-page--Color);
  --cn-c-catalog_cta--Color: var(--cn-c-page--BackgroundColor);

  background-color: var(--cn-c-catalog_cta--BackgroundColor);
  color: var(--cn-c-catalog_cta--Color);
  grid-column: 1 / -1;
  position: relative;

  .cn-c-image_wrapper {
    grid-column: 1 / -1;
  }

  img {
    width: 100%;
    object-fit: cover;
    height: pxToRem(477px);
  }

  .container {
    position: absolute;
    top: pxToRem(75px);
    left: 0;
    right: 0;
    bottom: 0;

    align-content: center;
    justify-items: center;
    align-items: center;
    padding-block: pxToRem(12px);
    padding-inline: var(--cn-global--content-grid--offsetsize);

    .text {
      grid-column: 1 / -1;
      grid-row: 1;
      text-align: center;
      width: 100%;

      h1 {
        grid-column: 1 / -1;
        justify-self: center;
        margin-block-end: pxToRem(5px);
        font-size: pxToRem(45px);
        line-height: pxToRem(50px);

        @media screen and (min-width: map.get($grid-breakpoints, sm)) {
          font-size: pxToRem(50px);
          line-height: pxToRem(54px);
        }

        @media screen and (min-width: map.get($grid-breakpoints, md)) {
          font-size: pxToRem(60px);
          line-height: pxToRem(64px);
        }

        @media screen and (min-width: map.get($grid-breakpoints, lg)) {
          grid-column: 1 / 6;
        }

        @media screen and (min-width: map.get($grid-breakpoints, xxl)) {
          font-size: pxToRem(70px);
          line-height: pxToRem(74px);
        }
      }

      h1 + h1 {
        grid-column: 1 / -1;
        justify-self: center;
        font-weight: var(--cn-global--FontWeight-regular);
        margin-block-end: pxToRem(22px);
        // text-align: end;

        @media screen and (min-width: map.get($grid-breakpoints, lg)) {
          grid-column: 4 / 7;
          justify-self: start;
          margin-block-end: pxToRem(49px);
        }
      }

      .cn-c-header_heading {
        grid-column: 1 / -1;
        padding-inline-start: unset;
        display: flex;
        flex-direction: row;
        gap: 0.3125rem;
        justify-content: center;
        margin-block-end: pxToRem(20px);

        @media screen and (min-width: map.get($grid-breakpoints, lg)) {
          grid-column: 4 / 7;
          justify-content: start;
        }
      }

      @media screen and (min-width: map.get($grid-breakpoints, lg)) {
        text-align: unset;
      }
    }

    .action {
      grid-column: 1 / -1;
      grid-row: 2;
      align-self: end;

      > .cn-c-label {
        grid-column: 1 / -1;
        padding-inline-start: unset;
        display: flex;
        flex-direction: row;
        gap: 0.3125rem;
        justify-content: center;
        font-size: pxToRem(18px);
        margin-block-end: pxToRem(30px);
        text-align: center;
        font: var(--cn-global--FontWeight-medium) pxToRem(24px) var(--cn-global--FontFamily-default);
        letter-spacing: 0.34px;
        line-height: pxToRem(29px);

        @media screen and (min-width: map.get($grid-breakpoints, lg)) {
          grid-column: 4 / 7;
          text-align: start;
        }
      }

      @media screen and (min-width: map.get($grid-breakpoints, lg)) {
        grid-column: 9 / span 3;
        grid-row: 1;
        margin-block-end: pxToRem(-28px);
      }
    }
  }

  .cn-c-button {
    --cn-c-button--hover--BackgroundColor: var(--cn-c-page--BackgroundColor);
    --cn-c-button--hover--Color: var(--cn-c-page--Color);
    font: var(--cn-global--Font-label--sm-semibold);
    display: block;
    margin-inline: auto;
    width: fit-content;
    text-align: center;

    @media screen and (min-width: map.get($grid-breakpoints, lg)) {
      grid-column: 9 / span 3;
      grid-row: 1;
      margin-inline: unset;
    }
  }
}
