/** ***************************************************************************
 * Ecommerce / Organisms / Featured Products
 *************************************************************************** */

 @use "sass:map";
@use 'utopia-core-scss/src/utopia' as utopia;
@use "~/src/styles/utilities.scss" as *;
@use "~/src/styles/shared/variables.module.scss" as *;

.cn-c-featured_products {
  > .container > h2 {
    grid-column: 1 / -1;
    margin-block-end: pxToRem(45px);
    width: fit-content;
    border-radius: 8px;
    background-image: linear-gradient(to bottom right, white 60%, transparent);
    z-index: 3;

    @media all and (min-width: map.get($grid-breakpoints, xl)) {
      margin-block-end: pxToRem(95px);
    }
  }

  .subtitle {
    grid-column: 1 / -1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: pxToRem(10px);
    margin-block-end: pxToRem(84px);
  }

  .product-list {
    grid-column: 1 / -1;
    overflow-x: auto;

    article {

      &:last-child {
        padding-inline-end: var(--cn-global--content-grid--offsetsize);
      }

      > div {
        min-width: pxToRem(263px);
        box-shadow: var(--cn-global--BoxShadow--default);
      }

      @include dispatchToContextBreakpoints($grid-breakpoints) {
        grid-column: span 3;

        &:last-child {
          padding-inline-end: unset;
        }
      }
    }

    @media all and (pointer:coarse) {
      scrollbar-width: none;
    }

    @media all and (min-width: map.get($grid-breakpoints, xl)) {
      overflow: visible;
    }
  }

  .images-slider {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: repeat(var(--cn-global--content-grid--md--columncount), 1fr);
    grid-gap: 0 var(--cn-c-page--Grid-Gap);
    grid-auto-flow: column;
    margin-inline-end: calc(-1 * var(--cn-global--content-grid--offsetsize));
    padding-block-end: pxToRem(10px);

    @media all and (min-width: 1200px) {
      margin-inline-end: 0;
    }

    @media all and (min-width: map.get($grid-breakpoints, xl)) {
      display: none;
    }
  }

  .cn-c-products_slider {
    display: none;

    .cn-c-image_wrapper img {
      width: unset;
    }

    @media all and (min-width: map.get($grid-breakpoints, xl)) {
      display: block;
      grid-column: 2 / -2;
    }
  }
}
